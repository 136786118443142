import './App.css';
import './assets/css/Style.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from './landingPage/LandingPage';
import { MetaServices } from './landingPage/ServicesPages/ServiceMeta';
import { WebARServices } from './landingPage/ServicesPages/ServiceAR';
import { BlockChain } from './landingPage/ServicesPages/BlockchainService';
import { ARVRTraning } from './landingPage/ServicesPages/ARVREducation';
import { ARVRXRGame } from './landingPage/ServicesPages/ARVRXRGame';
import { MobileApp } from './landingPage/ServicesPages/UIUXMobileApp';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { TermCondition } from './components/Term&Conditions';
import { Helmet } from 'react-helmet';
import { Blogs } from './Blogs';
import CollabSuite from './Collab_Suite';
import { BlogDetail } from './Blogs/BlogCardDetails/BlogDetail';

function App() {
  // Helper Component for SEO Metadata
  const RouteSpecificMeta = ({ title, description, pageURL }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={pageURL} />
    </Helmet>
  );

  // Individual Components with Meta
  const LandingPageWithMeta = () => (
    <>
      <RouteSpecificMeta title="Futurristic | Futurristic Business Solutions " description="Immersive tech solution provider. Vision pro app development, augmented reality, virtual reality, mixed reality, extended reality, Metaverse development, blockchain, and much more. " pageURL='https://futurristic.com/' />
      <LandingPage />
    </>
  );

  const MetaServicesWithMeta = () => (
    <>
      <RouteSpecificMeta title="Metaverse Development | Metaverse Services & Solutions" description="Explore cutting-edge Metaverse consulting services & solutions, expert Metaverse development, and immersive experiences tailored for your business growth. " pageURL='https://futurristic.com/metaverse-end-to-end-solutions' />
      <MetaServices />
    </>
  );

  const WebARServicesWithMeta = () => (
    <>
      <RouteSpecificMeta title="Web AR Development and Marketing Solutions | Web Based AR" description="Elevate your marketing efforts with WebAR development. Launch effective Web-based AR campaigns. Achieve more with our WebAR marketing/business solutions! " pageURL='https://futurristic.com/web-ar-based-marketing-solutions' />
      <WebARServices />
    </>
  );

  const BlockChainWithMeta = () => (
    <>
      <RouteSpecificMeta title="Blockchain Integration and Solutions  | Blockchain Integration and Development Services" description="Get expert consultation on custom blockchain integration and development with Futurristic. Ensure streamlined operations and enhanced security measures." pageURL='https://futurristic.com/blockchain-integration-and-solutions' />
      <BlockChain />
    </>
  );

  const ARVRTraningWithMeta = () => (
    <>
      <RouteSpecificMeta title="AR VR Training & Educational Platform" description="Create immersive learning environments with our augmented reality and virtual reality apps development services, focused on training and education excellence." pageURL='https://futurristic.com/ar-vr-training-&-educational-platform' />
      <ARVRTraning />
    </>
  );

  const ARVRXRGameWithMeta = () => (
    <>
      <RouteSpecificMeta title="Interactive AR/VR/XR Game Design & Development Services" description="Augmented Reality/Virtual Reality/Extended Reality/Unity/PC/Mobile Game design and development services and solutions. Metaverse integration game development." pageURL='https://futurristic.com/interactive-ar-vr-xr-game-developmen' />
      <ARVRXRGame />
    </>
  );

  const MobileAppWithMeta = () => (
    <>
      <RouteSpecificMeta title="Mobile Web & Enterprise Development Services" description="Develop custom mobile applications, web apps, and enterprise platforms like SaaS, PaaS, CRM, etc. to enhance your digital presence effectively." pageURL='https://futurristic.com/mobile-web-&-enterprise-development' />
      <MobileApp />
    </>
  );



  const PrivacyPolicyWithMeta = () => (
    <>
      <RouteSpecificMeta title="Privacy Policy" description="Read our privacy policy." pageURL='https://futurristic.com/privacypolicy' />
      <PrivacyPolicy />
    </>
  );

  const BlogDetail1 = () => (
    <>
      <RouteSpecificMeta title="Exploring the Future of Web-based Augmented Reality in Marketing" description="Uncover how web-based AR marketing solutions are transforming the future of marketing, offering immersive, engaging, and accessible experiences for businesses.
" pageURL='https://futurristic.com/blog-detail2' />
      <BlogDetail />
    </>
  );

  const BlogDetail2 = () => (
    <>
      <RouteSpecificMeta title="Top Strategies for Seamless Blockchain Integration and Solutions in 2025 " description="Achieve seamless custom blockchain integration with top strategies in 2025. Unlock innovative solutions to enhance security, scalability, and business efficiency." pageURL='https://futurristic.com/blog-detail2' />
      <BlogDetail />
    </>
  );

  const BlogDetail3 = () => (
    <>
      <RouteSpecificMeta title="The Role of Web AR in Modern Advertising Techniques" description="Discover how WebAR transforms advertising by enhancing engagement & interactivity. Explore innovative strategies to captivate audiences & elevate brand experiences." pageURL='https://futurristic.com/blog-detail3' />
      <BlogDetail />
    </>
  );

  const BlogDetail4 = () => (
    <>
      <RouteSpecificMeta title="Is the Metaverse Sector Alive? Latest Developments in the Virtual Space " description="Learn about the latest developments in the Metaverse sector and how metaverse services and solutions, along with expert consultation, are shaping its future." pageURL='https://futurristic.com/blog-detail4' />
      <BlogDetail />
    </>
  );

  const BlogDetail5 = () => (
    <>
      <RouteSpecificMeta title="Apple Vision Pro App Development: A Look Ahead to 2025
" description="Explore how Apple Vision Pro App Development is set to transform industries in 2025 with immersive features and innovative solutions for enhanced collaboration.
" pageURL='https://futurristic.com/blog-detail2' />
      <BlogDetail />
    </>
  );


  const TermConditionWithMeta = () => (
    <>
      <RouteSpecificMeta title="Terms and Conditions" description="Read our terms and conditions." pageURL='https://futurristic.com/termsandconditions' />
      <TermCondition />
    </>
  );
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPageWithMeta />} />
          <Route path="/metaverse-end-to-end-solutions" element={<MetaServicesWithMeta />} />
          <Route path="/web-ar-based-marketing-solutions" element={<WebARServicesWithMeta />} />
          <Route path="/blockchain-integration-and-solutions" element={<BlockChainWithMeta />} />
          <Route path="/ar-vr-training-&-educational-platform" element={<ARVRTraningWithMeta />} />
          <Route path="/interactive-ar-vr-xr-game-development" element={<ARVRXRGameWithMeta />} />
          <Route path="/mobile-web-&-enterprise-development" element={<MobileAppWithMeta />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyWithMeta />} />
          <Route path="/termsandconditions" element={<TermConditionWithMeta />} />
          <Route path='/collab-suite' element={<CollabSuite />} />
          <Route path='/blog' element={<Blogs />} />
          <Route path='/blog-detail1' element={<BlogDetail1 />} />
          <Route path='/blog-detail2' element={<BlogDetail2 />} />
          <Route path='/blog-detail3' element={<BlogDetail3 />} />
          <Route path='/blog-detail4' element={<BlogDetail4 />} />
          <Route path='/blog-detail5' element={<BlogDetail5 />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


import Footer from '../components/Footer/Footer'
import Header from "../components/Header/Header";
import ContactUs from "../components/ContactUs/ContactUs";
import { useRef } from "react";
import { BlogCards } from "./BlogCards/BlogCards";
import { Stack } from "react-bootstrap";
import { BlogBanner } from './BlogBanner';

export const Blogs = () => {

  const contactUsRef = useRef(null);

      const scrollToRefs = {
        contactUsRef,
      };

    return (
        <div>
            <Header scrollToRefs={scrollToRefs} blog={true} getInTouch={true} bgtertiary={true}/>
            <Stack direction='vertical' gap={5}>
            <BlogBanner/>
            <BlogCards/>
            </Stack>           
            <div ref={contactUsRef}><ContactUs/></div> 
            <Footer scrollToRefs={scrollToRefs}/>
        </div>
    );
}
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

const MainSlider = () => {
    const [index, setIndex] = useState(0);
    const [isClicked, setClicked] = useState(false);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

     
    return (
        <>
            <Carousel 
            activeindex={index} 
            onSelect={handleSelect} 
            controls={false}
             style={{ width: '100%', height: 'auto' }}
             >
                <Carousel.Item>
                    <video 
                    className='img-fluid MainSliderVideo' 
                    autoPlay
                     loop 
                     muted 
                     onEnded={() => setIndex(1)} 
                    poster="https://futurristic.s3.us-east-1.amazonaws.com/image/Blogs/futurristic-poster-image.png"
                    >
                        <source src='https://futurristic.s3.amazonaws.com/image/video/Metaverse/VisionPro.mp4' type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                    <Carousel.Caption style={{
                        textAlign: 'left',
                        left: '6%'
                    }}>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default MainSlider

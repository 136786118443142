import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap' ; 
import AOS from 'aos';
import 'aos/dist/aos.css';

const CollabUses = ( ) => {
    const [featuresCard] = useState([
        {
            Image:'https://futurristic.s3.us-east-1.amazonaws.com/image/collab/Collab-uses01.png',
            Heading: 'Manufacturing',
            usesPointOne: 'Overcome challenges with real-time solutions.',
            usesPointOnePara: 'Equip your employees with proper machine training and expert guidance to overcome manufacturing challenges—accessible from anywhere in the world.'
        },
        {
            Image:'https://futurristic.s3.us-east-1.amazonaws.com/image/collab/Collab-uses02.png',
            Heading: 'Automotive',
            usesPointOne: 'Streamline operations with virtual training & maintenance.',
            usesPointOnePara: "Optimize automotive operations through immersive virtual training and maintenance solutions, enhancing efficiency and reducing downtime across your workflow."
        },
        {
            Image:'https://futurristic.s3.us-east-1.amazonaws.com/image/collab/Collab-Uses03.png',
            Heading: 'Construction',
            usesPointOne: 'Enhance project efficiency with virtual collaboration.',
            usesPointOnePara: "Boost construction project efficiency through seamless virtual collaboration, streamlining communication, reducing delays, and enhancing teamwork across every phase."
        },
        {
           
            Image:'https://futurristic.s3.us-east-1.amazonaws.com/image/collab/Collab-Uses04.png',
            Heading: 'Maintainence & Repair',
            usesPointOne: 'Optimize repairs with an expert’s remote support & guidance.',
            usesPointOnePara: "Optimize the maintenance and repairs with expert remote support, ensuring efficient guidance and solutions right when you need them."
        },
    ]);

    useEffect(() => {
        AOS.init({ duration: 1000 })
    });

    
    return (
        <div className="mainContainer">

            <Container>

                <h1 className='text-center mb-1 fontWeight-800' style={{ color: '#0F75D6' }}>Useful for Industries Including…</h1>
                <h5 >Valuable for diverse sectors seeking efficient, scalable solutions. </h5>
              
                <div className='CollabContainer'>
                <Row>

                    {featuresCard.map((uses, index) => (

                        <Col md={12} sm={12} key={index} style={{ margin: '20px 0' }}>

                            <div className={`UsesCard ${index % 2 === 0 ? 'UsesCard01' : 'UsesCard02'} ${index % 2 === 0 ? 'text-start' : 'text-end'}`} data-aos="zoom-in">
                                <Stack direction='horizontal' gap={2} className={`align-items-center flex-column flex-md-row flex-lg-row ${index % 2 === 0 ? '' : 'flex-column-reverse flex-md-row-reverse flex-lg-row-reverse'}`}>
                                    <img src={uses.Image} alt="" className='img-fluid feature-image' />
                                    <div>
                                        <h2 style={{ color: '#000' }}>{uses.Heading}</h2>
                                        <Stack direction='vertical' gap={2}>
                                            <h5 style={{ color: '#0F75D6', fontWeight: 'bolder' }}>{uses.usesPointOne}</h5>
                                            <p style={{ fontSize: '1.2rem', color: '#000', fontWeight: '400', marginBottom: '0' }}>{uses.usesPointOnePara}</p>
                                        </Stack>
                                    </div>
                                </Stack>
                            </div>
                        </Col>
                    ))}
                </Row>
                </div>
            </Container>

        </div>
    );
};

export default CollabUses;

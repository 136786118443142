 import React, { useRef } from 'react' ;
import CollabSlider from './components/Main Slider/CollabSlider';
import CollabSection2 from './components/Section 2/CollabSection2';
import CollabAboutUS from './components/AboutUs/CollabAboutUs';
import CollabHowWorks from './components/HowItWorks/CollabHowWorks';
import CollabUses from './components/Uses/CollabUses';
import CollabFeatures from './components/Features/CollabFeatures';
import ContactUs from '../components/ContactUs/ContactUs';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { Stack } from 'react-bootstrap';
import { CollabBenefits } from './components/Benefits/CollabBenefits';

const CollabSuite = () => {
  const aboutUsRef = useRef(null);
  const featuresRef = useRef(null);
  const howItWorksRef = useRef(null);
  const contactUsRef = useRef(null);

  const scrollToRefs = {
    aboutUsRef,
    featuresRef,
    howItWorksRef,
    contactUsRef,
  };

  return (
    <Stack direction='vertical' gap={5}>
      <Header scrollToRefs={scrollToRefs} bgtertiary={true} features={true} howItWorks={true} aboutUs={true} getInTouch={true}/>
       <CollabSlider scrollToRefs={scrollToRefs}/> 
      <CollabSection2/>
      <div ref={aboutUsRef}><CollabAboutUS  /></div>
      <div ref={howItWorksRef}><CollabHowWorks/></div>
       <CollabUses />  
       {/* <CollabBenefits/> */}
      <div ref={featuresRef}><CollabFeatures/></div> 
      <div ref={contactUsRef}><ContactUs/></div> 
      <Footer scrollToRefs={scrollToRefs}/>
    </Stack> 
  )
}

export default CollabSuite;

import React, { useState, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Button, Container, Image, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const mainImg = 'https://futurristic.s3.us-east-1.amazonaws.com/image/collab/CollabMainImg.png'


const CollabSlider = ({ scrollToRefs }) => {
  const [index, setIndex] = useState(0);
  const carouselRef = useRef(null); 
 
  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      console.log('Scrolling to:', ref.current);  // Debugging output
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error('Reference is not defined or current is null:', ref);  // Debugging output
    }
  };

  const handleCollabrationClick = () => {
    scrollToRef(scrollToRefs.contactUsRef); // Scroll to contactUsRef
  };


  // const handleVideoEnd = () => {
  //   const newIndex = (index + 1) % 2; // Assuming you have 2 carousel items

  //   if (newIndex === 0) {
  //     setIndex(newIndex);
  //   } else {
  //     // Reset index to 0 if the second video ends
  //     setIndex(0);
  //   }

  //   if (carouselRef.current) {
  //     carouselRef.current.next();
  //   }

  //   // Auto-play the video when the slide changes
  //   const videos = document.querySelectorAll('.MainSliderVideo');
  //   videos.forEach((video, idx) => {
  //     if (idx === newIndex) {
  //       video.play();
  //     } else {
  //       video.pause();
  //     }
  //   });
  // };




  return (
    <>
      <Container>
        <Stack direction='vertical' gap={4} style={{ marginTop: '4rem' }}>
          <Stack direction='vertical' gap={2} className='justify-content-center align-items-center'>
              <h3 className="text-center">With Collab Suite,</h3>
              <div className='text-container'>
                <div className='collab-animated-text'>
                  <h3 className='fw-bold text-center' style={{ color: '#0F75D6' }}>Collaborate effortlessly with teams, experts, or customers, worldwide anytime</h3>
                  <h3 className='fw-bold text-center' style={{ color: '#0F75D6' }}>Overlay 3D models in real environments for instant problem-solving.</h3>
                  <h3 className='fw-bold text-center' style={{ color: '#0F75D6' }}>Cut down training costs and distance barriers with <Link to='/' className='text-decoration-none'>real-time immersive collaboration.</Link></h3>
                </div>
              </div>
            <Button variant='secondary' className='rounded-5' onClick={handleCollabrationClick} >Start Collabration</Button>
          </Stack>

          <Carousel ref={carouselRef} activeIndex={index} onSelect={setIndex} controls={false} interval={null}>
            <Carousel.Item>
              <Image src={mainImg} fluid alt='Collab duite image ' thumbnail />
            </Carousel.Item>
            {/* <Carousel.Item>
                       <Image src={mainImg} fluid alt='Collab duite image ' thumbnail/>
                    </Carousel.Item> */}
          </Carousel>
        </Stack>
      </Container>
    </>
  )
}

export default CollabSlider;

import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';


const CollabSection2 = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 })
});



  return (
    <Container  data-aos= 'fade-up '>
      <div className="CollabContainer">
        <Row className="justify-content-center g-3">
          <Col lg={3} md={3} xs={12}>
            <div className="CollabBox">
                <h5 className='text-center'>Immersive 3D simulations with Standard Operating Procedures (SOPs) </h5>
            </div>
          </Col>
          <Col lg={3} md={3} xs={12}>
          <div className="CollabBox">
                <h5  className='text-center'>Real-time voice and video collaboration</h5>
            </div>
          </Col>
          <Col lg={3} md={3} xs={12}>
          <div className="CollabBox">
                <h5 className='text-center'>Real-time collaboration on 3D models within real environment setup</h5>
            </div>
             </Col>
             <Col lg={3} md={3} xs={12}>
             <div className="CollabBox">
                <h5 className='text-center'>Easily accessible globally from any location</h5>
            </div>
             </Col>
        </Row>
      </div>
    </Container>
  );
};

export default CollabSection2;

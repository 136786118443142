import React, { useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
const aboutUsImage = 'https://futurristic.s3.us-east-1.amazonaws.com/image/collab/Collab6.gif'


const CollabAboutUS = ( ) => {
 
    useEffect(() => {
        AOS.init({ duration: 1000 })
    });

    return (
        <>
            <Container data-aos="fade-up">
                <div className='CollabAboutUs' >
                   
                    <div className="AboutUsText">
                        <h2 className='fw-bold text-primary'>Collaborate Anytime, Anywhere!</h2>
                        
                        <h5>Collab Suite is your go-to for effortless teamwork and real-time support, no matter where you are.
                            Join immersive sessions for learning, troubleshooting, or brainstorming, get real-time help, and turn
                            challenges into solutions—all while having a bit of fun. Connecting ideas, people, & technology by
                            powering innovation and infinite possibilities has never been this easy!
                        </h5>
                    </div>
                   
                   
                     <div className='AboutUsVideo '> 
                    
                    <Image src={aboutUsImage} />
                    
                    </div> 
                </div>
            </Container>
        </>
    );
};

export default CollabAboutUS;

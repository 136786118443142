import { Card, Container, Row} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Blog } from "../BlogData/BlogData";
import { IoIosArrowForward } from "react-icons/io";

export const BlogCards = () => {
    const navigate = useNavigate();

    const handleCardClick = (data, index) => {
        const url = '/blog-detail' + index;
        navigate(url, { state: data.details });
    }
    return <>
        <Container>
        <h3 className="text-start"><IoIosArrowForward />Latest Highlights</h3>

            <Row>

                {Blog.map((data, index) => {
                    return (
                        <div
                            key={index}
                            style={{cursor:'pointer'}}
                            className={`mb-4 ${index < 2 ? "col-md-6" : "col-md-4"}`}
                            onClick={() => handleCardClick(data, index + 1)}
                        >
                            <Card className="p-3 border-0 shadow" style={{ minHeight: '300px', height: '100%' }}>

                                <Card.Img variant="top" className='rounded' height={index < 2 ? 350 : 300} src={data.block.image} alt={`blog card image ${index}`}/>

                                <Card.Body className="text-start">
                                    <p className="mb-1">{data.block.date}</p>
                                    <Card.Title className='text-bold '>{data.block.title}</Card.Title>
                                    <Card.Text className="text-secondary">
                                        {data.block.description.slice(0, 100) + "..."}<Link to='/'>see more</Link>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    );
                })}
            </Row>
        </Container>
    </>
}
import { Container, Row, Col, Stack } from 'react-bootstrap';
import {  MdOutlineSecurity } from "react-icons/md";
import { GrSecure, GrTroubleshoot } from "react-icons/gr";
import { FiCommand, FiUserCheck } from "react-icons/fi";
import { BiCube, BiCustomize, BiUserVoice } from 'react-icons/bi';
import { BsPersonVideo3 } from 'react-icons/bs';
import { IoGlobeOutline } from "react-icons/io5";
import { VscTools } from "react-icons/vsc";


const CollabFeatures = () => {

    return (
        <Container>
            <h1 className='text-center mb-1 fontWeight-800' style={{ color: '#0F75D6' }}>Features That Stand Out </h1>
            <h5 className="mb-3">Explore the fantastic features of CollabSuite. See how it makes training and troubleshooting a breeze. </h5>

            <div className="CollabContainer" data-aos='fade-up'>

                <Row className='g-5'>

                    <Col lg={8} md={12} sm={12} xs={12}>
                        <Row className='g-3'>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className="FeatureMainHeading rounded-1"><span className="fw-bold text-white"> Manufacturers/IP Owners </span></div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className='pt-4'>
                                <div className="collabFeatureCard">
                                <div className="FeatureSubHeading "><span className="fw-bold text-white">Web Admin Panel</span></div>
                                    <ul style={{ listStyle: 'none', textAlign: 'start', padding: '0px' }}> <hr />
                                        <li className='px-2'><Stack direction='horizontal' gap='4'><BiCube className='text-primary fs-1 fw-bold'/><p>Managing the 3D models with simulations</p></Stack></li>  <hr />
                                        <li className='px-2'><Stack direction='horizontal' gap='4'><FiUserCheck className='text-primary fs-1 fw-bold'/><p>Control User Access with flexible administrative options </p></Stack></li> <hr />
                                        <li className='px-2'><Stack direction='horizontal' gap='4'><MdOutlineSecurity   className='text-primary fs-1 fw-bold'/><p>Enhanced security and IP protection enabled</p></Stack></li> <hr />
                                        <li className='px-2'><Stack direction='horizontal' gap='4'><BiCustomize  className='text-primary fs-1 fw-bold'/><p>Fully customizable for enhanced UX </p> </Stack></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className='pt-4'>
                                    <div className="collabFeatureCard">
                                    <div className="FeatureSubHeading "><span className="fw-bold text-white">Mobile or Desktop App </span></div>
                                        <ul style={{ listStyle: 'none', textAlign: 'start', padding: '0px' }}> <hr />
                                            <li  className='px-2'><Stack direction='horizontal' gap='4'><GrTroubleshoot  className='text-primary fs-1 fw-bold'/><p> Real-time troubleshooting & support via 3D model projection </p> </Stack></li> <hr />
                                            <li className='px-2'><Stack direction='horizontal' gap='4'><BiUserVoice  className='text-primary fs-1 fw-bold'/><p>Real-time voice, and video collaboration  </p> </Stack></li> <hr />
                                            <li className='px-2'><Stack direction='horizontal' gap='4'><IoGlobeOutline className='text-primary fs-1 fw-bold' /><p>3D model projections, simulation & interaction  </p></Stack></li>
                                        </ul>
                                    </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={4} md={12} sm={12} xs={12}>
                        <Row className='g-3' >
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className="FeatureMainHeading rounded-1"><span className="fw-bold text-white"> Client </span></div>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className='pt-4'>
                                <div className="collabFeatureCard">
                                <div className="FeatureSubHeading "><span className="fw-bold text-white">Vision Pro App</span></div>
                                    <ul style={{ listStyle: 'none', textAlign: 'start', padding: '0px' }}>  <hr />
                                        <li className='px-2'><Stack direction='horizontal' gap='4'><FiCommand  className='text-primary fs-1 fw-bold'/> <p>3D model projections, simulation & interaction </p></Stack></li> <hr />
                                        <li className='px-2'><Stack direction='horizontal' gap='4'><BsPersonVideo3  className='text-primary fs-1 fw-bold'/><p>Instant voice and video collaboration with real-time connectivity </p></Stack></li> <hr />
                                        <li className='px-2'><Stack direction='horizontal' gap='4'><VscTools className='text-primary fs-1 fw-bold'/><p>Immersive training with interactive tools</p></Stack></li> <hr />
                                        <li className='px-2'><Stack direction='horizontal' gap='4'><GrSecure  className='text-primary fs-1 fw-bold'/><p>Secured access </p></Stack></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>


            </div>
        </Container>
    );
};

export default CollabFeatures;

import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Stack } from "react-bootstrap";

export const BlogDetail = () => {
    const location = useLocation();
    const [details, setDetails] = useState("");
    const contactUsRef = useRef(null);
    const scrollToRefs = {
        contactUsRef,
    };


    useEffect(() => {
        if (location && location?.state) {
            setDetails(location?.state);
        }
    }, [location])

 

    return (
        <>
            <Stack direction='vertical' gap={5}>
                <Header bgtertiary={true} blog={true} />
                <div className='mt-5' dangerouslySetInnerHTML={{ __html: details }}></div>
                <Footer scrollToRefs={scrollToRefs} />
            </Stack>

        </>


    );
};

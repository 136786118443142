import React from 'react' 
import { BannerPrivacy } from './Banner'
import { PrivacyContent } from './PrivacyContent'
import Header from '../Header/Header'

export const PrivacyPolicy = () => {
  return (
    <>
      {/* <TermsAndPrivacyHeader/> */}
      <Header />
      <BannerPrivacy/>
      <PrivacyContent/>
      {/* <Footer/> */}
    </>
  )
}

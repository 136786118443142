import React, { useEffect, useState } from 'react';
import { Button, NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { CgMenuHotdog } from 'react-icons/cg';
import { Link } from 'react-router-dom';


const Header = ({ scrollToRefs, features, howItWorks, service, portfolio, blog, aboutUs, getInTouch, bgtertiary }) => {
  const [isSticky, setSticky] = useState(false);

  const scrollToRef = (ref) => {
    console.log(ref);
    if (ref && ref.current) {
      console.log('Scrolling to:', ref.current);  // Debugging output
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error('Reference is not defined or current is null:', ref);  // Debugging output
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`NavParent ${isSticky ? 'sticky' : ''}`} style={{ position: 'relative' }}>
      <Navbar expand="lg" fixed="top" className={`navChild ${bgtertiary ? 'bg-body-tertiarys navbar-scrolled' : isSticky ? 'navbar-scrolled': ''}`} style={{
        width: '100%',
        zIndex: 999,
      }}>
        <Container>
          <Navbar.Brand href="/" style={{
            fontFamily: 'Nunito, sans-serif',
            fontWeight: 'bold',
            color: '#fff',
            width: '25%'
          }}>
            <img src="https://futurristic.s3.amazonaws.com/image/MainLogo.png" alt="futurristic-logo" className="img-fluid w-100 text-left p-2" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <CgMenuHotdog />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/" className='text-white navLinkHover fontWeight-800 mx-2'>Home</Nav.Link>
             {aboutUs && (<Nav.Link onClick={() => scrollToRef(scrollToRefs.aboutUsRef)} className='text-white navLinkHover fontWeight-800 mx-2'>About Us</Nav.Link>)}
             {service && (<Nav.Link onClick={() => scrollToRef(scrollToRefs.serviceRef)} className='text-white navLinkHover fontWeight-800 mx-2'>Services</Nav.Link>)}
             {portfolio && (<Nav.Link onClick={() => scrollToRef(scrollToRefs.portfolioRef)} className='text-white navLinkHover fontWeight-800 mx-2'>Portfolio</Nav.Link>)}
              {features && ( <Nav.Link onClick={() => scrollToRef(scrollToRefs.featuresRef)} className='text-white navLinkHover fontWeight-800 mx-2'>Features</Nav.Link>)} 
              {howItWorks && ( <Nav.Link onClick={() => scrollToRef(scrollToRefs.howItWorksRef)} className='text-white navLinkHover fontWeight-800 mx-2'>How it Works</Nav.Link>)}
              <NavDropdown title="Our Products" className="text-white navdropdown fontWeight-800 mx-2" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to='https://futurristic.com/fexperience/' target="_blank" className='text-dark navLinkHover text-center fontWeight-800 '>Fxperience</NavDropdown.Item>
                <NavDropdown.Item href="/collab-suite" className='text-dark navLinkHover text-center fontWeight-800'>Collab Suite</NavDropdown.Item>
              </NavDropdown>
              {blog && (<Nav.Link href="/blog" className='text-white navLinkHover fontWeight-800 mx-2'>Blog</Nav.Link>)}
              {getInTouch && (<Button onClick={() => scrollToRef(scrollToRefs.contactUsRef)} type='submit' variant='light' className='GetTouch rounded-5 fontWeight-600 mx-2'>Get In Touch</Button>)}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;

import React from 'react'
import { BannerCondition } from './Banner'
import { TermConditionContent } from './TermConditionContent'
import Header from '../Header/Header'


export const TermCondition = () => {
  return (
    <>
      <Header/>
      <BannerCondition/>
      <TermConditionContent/>
    </>
  )
}
